import { useLayoutEffect, useRef } from "react";
import orismaLogo from "../assets/images/company/orisma.jpg";
import sourcecodeLogo from "../assets/images/company/sourcecode.jpg";
import taximailLogo from "../assets/images/company/taximail.png";
import ScrollContainer from "../components/ScrollContainer";
import { BoxCustomProps } from "../components/boxCustom";
import { ExperienceComponent } from "../components/experience/experience";
import Marquee from "../components/marquee";

import { MouseComponent } from "../components/mouse";

const texts = ["CODE", "LOVER"];
const experience: BoxCustomProps[] = [
    {
        index: "01",
        title: [
            { value: "Full stack Developer" },
            { value: "@ Source Code", sm: true },
        ],
        year: "2024 - Present",
        tags: ["React", "Flutter", "Node.js", "MySql"],
        companyLogo: sourcecodeLogo,
        location: "Bangkok, Thailand",
        companyUrl: {
            url: "https://sourcecode.co.th/",
            text: "sourcecode.co.th",
        },
        companyDescription: "Contributed to the development of a room management application, enabling seamless service requests, payment tracking, maintenance, and real-time updates. Also worked on a collaborative web application with interactive whiteboards, real-time collaboration, user access control, and efficient data management.",
    },
    {
        index: "02",
        title: [{ value: "Senior Developer" }, { value: "@ Orisma", sm: true }],
        year: "2024",
        tags: ["PHP", "Node.js", "MySql", "JQuery"],
        companyLogo: orismaLogo,
        location: "Bangkok, Thailand",
        companyUrl: {
            url: "https://www.orisma.com/",
            text: "orisma.com",
        },
        companyDescription:
            "Spearheaded all phases of web application development for HR management, from conceptualization to testing and implementation, using PHP, HTML, LESS, and Node.js.",
    },
    {
        index: "03",
        title: [{ value: "Developer" }, { value: "@ Taximail", sm: true }],
        year: "2020 - 2023",
        tags: [
            "ReactJs",
            "Vue2",
            "Flutter",
            "MySql",
            "PostgreSQL",
            "PHP",
            "Node.js",
        ],
        companyLogo: taximailLogo,
        location: "Bangkok, Thailand",
        companyUrl: {
            url: "https://www.taximail.com/",
            text: "taximail.com",
        },
        companyDescription:
            "Developing comprehensive front-end and back-end solutions for various company projects. My work includes integrating third-party services such as Zapier, HubSpot, and Line, using technologies like Flutter, MySQL, PHP, Node.js, and more to deliver robust and efficient solutions.",
    },
];

export const Experience = () => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        document.body.setAttribute("data-theme", "yellow");
    });
    return (
        <ScrollContainer nodeRef={scrollContainerRef}>
            <main
                className="content-container"
                style={{ paddingBottom: "0rem" }}
            >
                <section className="section-content-container">
                    <div className="inset-0 full-screen-section-container">
                        <div
                            className="absolute full-screen-section"
                            style={{ top: 0, left: 0, zIndex: 0 }}
                        >
                            <Marquee texts={texts} />
                        </div>
                        <div className="full-screen-section intro-content-container">
                            <span className="title-page">EXPERIENCE</span>

                            <h1>
                                A Chronicle of <br />
                                My <span>Journey</span>
                            </h1>
                            <p>
                                With numerous years as a software developer, I
                                have gained extensive experience across various
                                industries and technologies. Allow me to share
                                some of my most noteworthy experiences with you.
                            </p>
                        </div>
                        <MouseComponent
                            scrollContainerRef={scrollContainerRef}
                        />
                    </div>
                </section>

                <section className="section-content-container">
                    <div
                        className="inset-0 normal-content-container"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            overflow: "unset",
                            padding: "1rem",
                        }}
                    >
                        <ExperienceComponent
                            scrollContainerRef={scrollContainerRef}
                            children={experience}
                        />
                    </div>
                </section>
            </main>
        </ScrollContainer>
    );
};
